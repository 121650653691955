export type ModalScreensType =
  | 'backmarket'
  | 'verifyConnection'
  | 'connectionFailed'
  | 'connectionStarted'
  | 'connectionSuccess'
  | 'inviteMembers'
  | 'fileUpload'
  | 'stripeSuccess'
  | 'twoFa'
  | 'openbanking'
  | 'addbank'
  | 'stripecard'
  | 'repayCalc'
  | 'newBill'
  | 'getFunding'
  | 'billVideos'
  | 'welcome'
  | 'null'
  | 'archiveBill'
  | 'bulkBillSuggestion'
  | 'addBankAdvance'
  | 'vendorCreationSuccessMessage'

interface ModalState {
  isOpen: boolean
  selectedInvoice?: File | null
  type: ModalScreensType
}
const modalState = ref<ModalState>({
  isOpen: false,
  selectedInvoice: null,
  type: 'null',
})

const setModalState = (value: ModalState) => {
  modalState.value = value
}

export { modalState, setModalState }
